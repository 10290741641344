<template>
  <v-container>
    <FilterGroup class="mb-4" :filters="tabs" :routeRoot="'/products'" />

    <router-view />
  </v-container>
</template>

<script>
import FilterGroup from "@/components/filters/FilterGroup";

export default {
  components: {
    FilterGroup,
  },

  data() {
    return {
      tabs: [
        { label: "Produtos", value: "" },
        { label: "Categorias", value: "/categories" },
      ],
    };
  },
};
</script>

<style></style>
